<template>
  <div>
    <!-- pic0D61CA8E15D2E83EABC72D4203FEB5FA.jpg -->
    <img class="img-large rounded" src="">

    <p>
      The Maelstrom is outmatching the minifigs you're sending to my Universe. The dark menace infects more and more creatures!
    </p>
    <p>
      Can you help me and my fellow minifigs prepare for its next attack? Create creatures you imagine the Maelstrom might use against us in battle!
    </p>
    <p>
      Dash to the <router-link :to="{ name: 'creation-lab-list', params: {challengeId: '8a5121d4-46a5-411f-a52a-a461238de602'} }">Creation Lab</router-link> to make a Maelstrom infected monster!
    </p>
  </div>
</template>
